/*
 * @Description:
 * @FilePath: /low-code-platform/src/services/duplicate/index.ts
 * :author: wu.shangru
 * :copyright: (c) 2023, Tungee
 * @Date: 2023-07-30 14:31:46
 * @LastEditors: wu.shangru
 * @LastEditTime: 2023-12-06 10:52:47
 */
import { getRequest, postRequest } from '@/common/http';

enum SearchType {
  EQ = 'EQ',
  SEARCH = 'SEARCH',
}

export interface IConditionField {
  fieldId: string;
  filterType: SearchType;
  title: string;
}

export interface IVisibleField {
  editable: boolean;
  fieldId: string;
  hidden: boolean;
  title: string;
}

export interface IDuplicateListData {
  duplicationRule: {
    manyQueryConditionList: Array<IConditionField>;
    enableIgnoreCaseSensitive?: boolean;
    enableExcludeSpaces?: boolean;
  };
  formCode: string;
  formDuplicationResultVisibleField: {
    visibleFieldList: Array<IVisibleField>;
  };
  status: number;
}

export const saveUkSettings = (params: IDuplicateListData) => {
  return postRequest('/api/form/extendSetting/saveFormDuplicationRule', params);
};

export const getDuplicateFieldList = (params: { formCode: string }) => {
  return postRequest<IDuplicateListData>(
    '/api/form/extendSetting/getFormDuplicationRule',
    params,
  );
};

//联合查重规则

//查看联合查重规则列表
export async function getFormUnionDuplicationRuleList(formCode: string) {
  return postRequest(
    '/api/form/extendSetting/getFormUnionDuplicationRuleList',
    { formCode },
  );
}

interface IUnionDuplicationRule {
  duplicationRule?: {
    //查重规则
    manyQueryConditionList?: any;
  };
  formCode?: string; //表单code
  formDuplicationResultVisibleField?: {
    //	查重结果显示字段
    visibleFieldList?: any;
  };
  skipRuleSizeCheck?: boolean; //跳过规则数量检查
  status?: any; // 是否启用：0-关闭，1-启用
  targetFormCode?: string; //目标表 formCode
  targetFormTitle?: string; //	目标表 title
}

//编辑表单联合查重规则
export async function editFormUnionDuplicationRule(
  ruleId: string,
  data: IUnionDuplicationRule,
) {
  return postRequest(
    `/api/form/extendSetting/editFormUnionDuplicationRule/${ruleId}`,
    data,
  );
}

//保存表单联合查重规则
export async function saveFormUnionDuplicationRule(
  data: IUnionDuplicationRule,
) {
  return postRequest(
    `/api/form/extendSetting/saveFormUnionDuplicationRule`,
    data,
  );
}

// 删除表单联合查重规则
export async function delFormUnionDuplicationRule(
  ruleId: string,
  formCode: string,
) {
  return postRequest(`/api/form/extendSetting/delFormUnionDuplicationRule`, {
    ruleId,
    formCode,
  });
}

//更新表单联合查重规则状态
export async function updateFormUnionDuplicationRuleStatus(
  ruleId: string,
  formCode: string,
  status: any,
) {
  return postRequest(
    `/api/form/extendSetting/updateFormUnionDuplicationRuleStatus`,
    { ruleId, formCode, status },
  );
}

//获取联合查重可选择表单列表
export async function getUnionDuplicationFormList(formCode: string) {
  return postRequest(`/api/form/extendSetting/getUnionDuplicationFormList`, {
    formCode,
  });
}
