/*
 * :file description:
 * :name: /low-code-platform/src/pages/generator/relation/_layout.tsx
 * :author: xiaoguotong
 * :copyright: (c) 2024, Tungee
 * :date created: 2024-01-05 18:19:54
 * :last editor: KyleLiang
 * :date last edited: 2024-08-05 17:24:46
 */

import IconFont from '@/components/IconFont';
import {
  getVisibleFormList,
  isInWhiteList,
} from '@/services/extension-setting';
import { getFormWidgetPropertyLimit } from '@/pages/generator/form/services/form-generator';
import { Menu } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { history, useLocation, useModel } from 'umi';
import { customJP } from '~@/packages/form-render/src/utils';
import MenuData, {
  FREE_MENU_DATA,
  SYSTEM_FLOW_FIELDS,
  BUSINESS_RELATION_MENUS,
  MENU_DATA_MAP,
  VERSION_TYPE,
} from './constants';
import CoreContext from './context';
import { flattenSchema } from './utils';
import c from './_layout.less';
import { getFormSchemaForDesignStatus } from '../form/services/form-generator';
import { getUrlParam } from '@/utils';
import { useGray } from './hooks';
import { CUSTOMER_BIZ_TYPES } from '~@/packages/form-render/src/widgets/DataSnippet/contants';
import { isOpenInTripartitePlatform } from '@/utils/tripartitePlatform';

export default (props: any) => {
  const [schemaList, setSchemaList] = useState([]);

  // const [byoCustomer, setByoCustomer] = useState(true); //当前版本 true:独立版CRM false:一方品CRM

  // const [unionRepeated,setUnionRepeated]=useState(false);//联合查重灰度控制
  const customerUnionRepeated = useGray('customer_union_repeated'); //个人客户联合查重灰度控制
  const unionRepeated = useGray('union_repeated');
  const byoCustomer = useGray('byoCustomer');

  const [menuDataList, setMenuDataList] = useState(MenuData);
  const [formProperty, setFormProperty] = useState({
    formProperties: [],
    formVersions: [],
    widgetProperties: {},
  });
  const location = useLocation();
  const isDuplicatGray = useGray('duplicate_check');
  const formDataSharing = useGray('form_data_sharing');
  const { source, formCode, versionValue } = location?.query || {};

  const { updateProfile } = useModel('useGenerator', (model) => ({
    ...model,
    formcode: model.formCode,
  }));

  const schemaBizsuiteToRelation = (schema: any) => {
    let result = {};
    Object.entries(schema['properties'] || {}).forEach(function ([key, value]) {
      if (key.startsWith('bizSuite')) {
        result = value['properties'];
      }
    });
    schema['properties'] = { ...schema['properties'], ...result };
    return schema;
  };
  const addProcessInSchema = (schema: any, title?: string) => {
    const dfs = (obj: any, title?: string) => {
      if (!obj.properties) {
        return;
      }
      const keys = Object.keys(obj.properties);
      if (!keys.length) {
        return;
      }
      obj.properties = {
        ...obj.properties,
        ...SYSTEM_FLOW_FIELDS,
      };
      keys.map((item) => {
        if (item?.includes('relation')) {
          dfs(obj.properties[item], obj.properties[item]);
        }
      });
    };
    dfs(schema, schema?.title);
    return schema;
  };
  const getPropertyLimit = async () => {
    const res = await getFormWidgetPropertyLimit();
    setFormProperty(res?.data ?? {});
  };

  // 扩展设置功能过滤
  // 自定义打印功能对所有用户都展示，其余功能保持原有逻辑
  // 版本  -1 试用版  0 免费版 1 标准版 2 专用版 3 企业版
  // 1 2 3 跟后台数据走
  const featureFilter = useMemo(() => {
    let _menuDataList = [];
    const _formProperties = formProperty?.formProperties || [];
    if (source === 'dataPush') {
      _menuDataList = menuDataList;
    } else if (
      formProperty?.formProperties?.length > 0 &&
      source === 'CloudPlatform'
    ) {
      _menuDataList = menuDataList;
    } else if ([2, 3, 1].includes(Number(versionValue))) {
      // 前端和后端的key对应不上
      let formPropertiesKey = [];
      if (formProperty?.formProperties?.length > 0) {
        formPropertiesKey = formProperty?.formProperties?.map(
          (item) => MENU_DATA_MAP[item],
        );
      } else {
        formPropertiesKey = [];
      }

      _menuDataList = menuDataList.filter(
        (v) => formPropertiesKey?.includes(v.key) || v.key === 'print',
      );
    } else {
      // 判断为试用版 直接不渲染业务关联、表单校验
      // 非试用版 通过接口判断是否显示
      _menuDataList = menuDataList.filter((v) => {
        if (Number(versionValue) === -1) {
          return FREE_MENU_DATA.includes(v.key);
        } else {
          return (
            _formProperties.some((item) => BUSINESS_RELATION_MENUS.has(item)) ||
            FREE_MENU_DATA.includes(v.key)
          );
        }
      });
    }
    // 查重针对免费版、未灰度到的组织、erp 不显示
    if (
      !isOpenInTripartitePlatform('1688') &&
      source !== 'CloudPlatform' &&
      (0 === Number(versionValue) || !isDuplicatGray)
    ) {
      _menuDataList = _menuDataList.filter((v) => v.key !== 'duplicated');
    }
    //联合查重代码回滚  先注销
    //查重菜单控制
    // if (byoCustomer) {
    //   //独立版CRM
    //   // 试用版、标准版、企业版、旗舰版可用【全部查重功能】
    //   // 版本变更处理：当用户变更为免费版时，查重设置入口关闭
    //   if (Number(versionValue) === VERSION_TYPE.FREE) {
    //     _menuDataList = _menuDataList.filter((v) => v.key !== 'duplicated');
    //   }
    // } else {
    //   //一方品CRM  window?.isBuyIntegrationApp
    //   // 试用版、旗舰版仅可用【查重规则、合并规则】，不支持【联合查重】
    //   // 版本变更处理：当用户变更为免费版、标准版、企业版，查重设置入口关闭
    //   if (
    //     window?.isBuyIntegrationApp &&
    //     [
    //       VERSION_TYPE.FREE,
    //       VERSION_TYPE.STANDARD,
    //       VERSION_TYPE.ENTERPRISE,
    //     ].includes(Number(versionValue))
    //   ) {
    //     _menuDataList = _menuDataList.filter((v) => v.key !== 'duplicated');
    //   }
    // }

    // 1688、erp、免费版本、不灰度  不显示
    if (
      isOpenInTripartitePlatform('1688') ||
      source === 'CloudPlatform' ||
      0 === Number(versionValue) ||
      !formDataSharing
    ) {
      _menuDataList = _menuDataList.filter((v) => v.key !== 'shareData');
    }
    return _menuDataList;
  }, [
    menuDataList,
    isDuplicatGray,
    formProperty?.formProperties,
    formDataSharing,
  ]);

  useEffect(() => {
    getPropertyLimit();
    getVisibleFormList().then((res) => {
      const data = res?.data.filter((item) => item.source !== 2) ?? [];
      const dingData = res?.data
        .filter(
          (item) =>
            item.source === 2 && CUSTOMER_BIZ_TYPES.includes(item.bizType),
        )
        .map((item) => {
          const properties = JSON.parse(item.content).properties;
          const _properties: any = {};
          Object.keys(properties)?.forEach((key) => {
            switch (properties[key].widget) {
              case 'textarea':
              case 'money':
              case 'select':
              case 'multiSelect':
                _properties[key] = properties[key];
                break;
              case 'number':
                const numberComponentName =
                  properties[key]?.dingtalkComponentName;
                if (
                  !numberComponentName ||
                  numberComponentName === 'NumberField'
                ) {
                  _properties[key] = properties[key];
                }
                break;
              case 'input':
                const dingtalkComponentName =
                  properties[key]?.dingtalkComponentName;
                if (
                  !dingtalkComponentName ||
                  dingtalkComponentName === 'TextField'
                ) {
                  _properties[key] = properties[key];
                }
                break;
            }
          });
          item.content = JSON.stringify({
            type: 'object',
            id: '#',
            properties: _properties,
          });
          return item;
        });
      let curFormSchema = [];
      [...dingData, ...data].forEach((item) => {
        let parseSchema = customJP(item.content);
        parseSchema = schemaBizsuiteToRelation(parseSchema);
        // 去掉硬编码
        // parseSchema = addProcessInSchema(parseSchema, item?.title);
        const data = flattenSchema(parseSchema, item.formCode, item.title);
        curFormSchema.push(data);
      });
      setSchemaList(curFormSchema);
    });

    isInWhiteList({ code: 'connector' }).then((res: any) => {
      const isInWhiteList = res?.result || false;
      if (isInWhiteList) {
        if (source === 'dataPush') {
          const newMenuData = [
            {
              title: '数据推送',
              icon: 'guanlianzuzhi',
              path: '/generator/relation/dataConnector',
            },
          ];
          setMenuDataList(newMenuData);
        } else if (source !== 'CloudPlatform') {
          const newMenuData = MenuData.concat({
            title: '数据推送',
            icon: 'guanlianzuzhi',
            path: '/generator/relation/dataConnector',
          });
          setMenuDataList(newMenuData);
        }
      }
      // if (isInWhiteList && source !== 'CloudPlatform') {
      //   const newMenuData = MenuData.concat({
      //     title: '数据推送',
      //     icon: 'guanlianzuzhi',
      //     path: '/generator/relation/dataConnector',
      //   });
      //   setMenuDataList(newMenuData);
      // }
    });

    // isInWhiteList({ code: 'union_repeated' }).then((res: any) => {
    //   const isInWhiteList = res?.result || false;
    //   setUnionRepeated(isInWhiteList);
    // });

    // isInWhiteList({ code: 'byoCustomer' }).then((res: any) => {
    //   const isInWhiteList = res?.result || false;
    //   setByoCustomer(isInWhiteList);
    // });

    // 先判断是否缓存表单信息
    const data =
      sessionStorage.getItem(
        `tungee_design_form_${window.__corpId || getUrlParam('corpId')}`,
      ) ?? '';
    if (data === '') {
      getFormSchemaForDesignStatus({ formCode }).then((res) => {
        const { icon, iconColor, description, title, bizType, formType } =
          res?.data || {};
        const profile = {
          icon,
          iconColor,
          description,
          title,
          bizType,
          formType,
        };
        // 暂时加个缓存处理
        sessionStorage.setItem(
          `tungee_design_form_${window.__corpId || getUrlParam('corpId')}`,
          JSON.stringify(profile),
        );
        updateProfile(profile);
      });
    }
  }, []);

  return (
    <CoreContext.Provider
      value={{ schemaList, byoCustomer, unionRepeated, customerUnionRepeated }}
    >
      <div className={c.layout}>
        <Menu
          selectedKeys={[location.pathname]}
          mode="inline"
          theme="light"
          className={c.menu}
          onOpenChange={(openKeys) => {}}
        >
          {featureFilter.map((item) => (
            <Menu.Item
              key={item.path}
              icon={<IconFont type={item.icon} className="menu-icon" />}
              onClick={() => {
                history.push(`${item.path}${location?.search}`);
              }}
            >
              {item.title}
            </Menu.Item>
          ))}
        </Menu>
        <div className={c.content}>{props.children}</div>
      </div>
    </CoreContext.Provider>
  );
};
