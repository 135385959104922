/*
 * :file description:
 * :name: /low-code-platform/src/pages/generator/relation/duplicated/components/FieldSettingModal/GroupCondition.tsx
 * :author: KyleLiang
 * :copyright: (c) 2024, Tungee
 * :date created: 2024-05-06 13:50:00
 * :last editor: KyleLiang
 * :date last edited: 2024-08-05 10:16:17
 */
import React, { useState, useEffect, useMemo } from 'react';
import { Select, Divider, Button, Cascader, Menu, Checkbox } from 'antd';
import { cloneDeep, isEmpty } from 'lodash';
import cx from 'classnames';
import IconFont from '@/components/IconFont';
import RenderDupText from './RenderDupText';
import FuzzyMatchingSettingModal from '@/customPage/pages/CRM/duplicateCheck/index';
import {
  SearchTypeOption,
  CascaderOption,
  NOT_SUPPORT_WIDGETS,
  SearchOption,
  DOUBLE_SEARCHTYPE_WIDGETS,
  GMT_CREATE,
  TableSearchTypeOption,
  TableSearchTypeIN,
  SELECT_ENGLISH_RULE,
} from '../../constants';

const { Option } = Select;

const GroupCondition = (props) => {
  const {
    updateDuplicateData,
    duplicateData,
    handleChangeFields,
    formName,
    addressWidgetGray,
    showEnglishRule,
  } = props;
  const [conditionList, setConditionList] = useState(
    updateDuplicateData.manyQueryConditionList,
  );
  const [englishRule, setEnglishRule] = useState([
    updateDuplicateData?.enableIgnoreCaseSensitive || false,
    updateDuplicateData?.enableExcludeSpaces || false,
  ]);
  const [fuzzyMathingVisible, setFuzzyMathingVisible] = useState(false);
  useEffect(() => {
    const visibleFieldList = getHiddenFields(conditionList);
    handleChangeFields({
      manyQueryConditionList: conditionList,
      enableIgnoreCaseSensitive: englishRule[0],
      enableExcludeSpaces: englishRule[1],
      visibleFieldList,
    });
  }, [conditionList, englishRule]);

  useEffect(() => {
    if (conditionList.length === 0) {
      addConditionGroup();
    }
  }, []);

  const getHiddenFields = (conditionList) => {
    const isSelectedFields = conditionList.flat();
    const visibleIds = duplicateData.visibleFieldList.map(
      (item) => item.fieldId,
    );
    let _formList = duplicateData.selectFields
      .concat(GMT_CREATE)
      .sort((a, b) => {
        if (
          visibleIds.indexOf(a.id) === -1 ||
          visibleIds.indexOf(b.id) === -1
        ) {
          return -1;
        }
        return visibleIds.indexOf(a.id) - visibleIds.indexOf(b.id);
      })
      .map((item) => {
        const _visibleField = duplicateData.visibleFieldList.find(
          (x) => x.fieldId === item.id,
        );
        const isSelectedField = isSelectedFields.some(
          (x) => x.fieldId === item.id,
        );
        let hidden = isSelectedField ? false : true;
        if (_visibleField?.fieldId) {
          return {
            ..._visibleField,
            hidden: _visibleField.hidden ? hidden : _visibleField.hidden,
          };
        }
        if (item.id === 'principal') {
          hidden = false;
        }
        return {
          hidden,
          fieldId: item.id,
          title: item.title,
          editable: true,
        };
      });
    return _formList;
  };

  const addConditionField = (index: number) => {
    const _clonelist = [...conditionList];
    if (_clonelist[index].length >= 5) {
      return;
    }
    _clonelist[index].push({});
    setConditionList(_clonelist);
  };

  const deleteConditionField = (index: number, fieldInd: number) => {
    const _clonelist = [...conditionList];
    const _fieldVOList = _clonelist[index] ?? [];
    if (_fieldVOList.length === 1) {
      _clonelist.splice(index, 1);
    } else {
      _fieldVOList.splice(fieldInd, 1);
    }
    setConditionList(_clonelist);
  };

  const addConditionGroup = () => {
    const data =
      duplicateData.selectFields.filter(
        (item) => !NOT_SUPPORT_WIDGETS.includes(item.widget),
      )?.[0] ?? {};
    const _clonelist = [...conditionList];
    _clonelist.push([
      {
        fieldId: data.id,
        filterType: 'EQ',
        title: data.title,
        value: null,
        values: null,
      },
    ]);
    setConditionList(_clonelist);
  };

  const onChangeSearchType = (
    groupIndex: number,
    fieldIndex: number,
    val: any,
  ) => {
    let params: any = {};
    if (Array.isArray(val)) {
      params.filterType = val[0];
      params.values = [val[1]];
    } else {
      params.filterType = val;
    }
    const _data = conditionList.map((condition, index) => {
      if (index === groupIndex) {
        return condition.map((item, idx) => {
          if (idx === fieldIndex) {
            return {
              ...item,
              ...params,
            };
          }
          return item;
        });
      }
      return condition;
    });
    setConditionList(_data);
  };

  const handleSelected = (val: any) => {
    // 当检测到了是模糊匹配，且灰度了，则弹出模糊匹配设置弹窗
    if (typeof val === 'string' && val === 'SEARCH' && addressWidgetGray) {
      setFuzzyMathingVisible(true);
    }
  };

  const onHandleChange = (
    groupIndex: number,
    fieldIndex: number,
    option: any,
  ) => {
    const _clonelist = cloneDeep(conditionList);
    const selectedField = duplicateData.selectFields.find(
      (item) => item.id === option.value,
    );
    if (option?.tableId) {
      const tableSelectedField = duplicateData.selectFields.find(
        (item) => item.id === option.tableId,
      );
      Object.keys(tableSelectedField?.items?.properties || {}).forEach(
        (item) => {
          const childItem = tableSelectedField?.items?.properties[item] || {};
          if (
            childItem &&
            JSON.stringify(childItem) !== '{}' &&
            childItem?.id === option.value
          ) {
            _clonelist[groupIndex].splice(fieldIndex, 1, {
              fieldId: childItem?.id,
              tableFieldId: childItem?.tableId,
              tableFieldTitle: tableSelectedField?.title,
              title: childItem?.title,
              filterType: 'IN',
            });
          }
        },
      );
    } else {
      _clonelist[groupIndex].splice(fieldIndex, 1, {
        fieldId: selectedField?.id,
        title: selectedField?.title,
        filterType: 'EQ',
      });
    }
    setConditionList(_clonelist);
  };

  const handleEnglishRuleChange = (key: number, checked: boolean) => {
    const _englishRule = [...englishRule];
    _englishRule[key] = checked;
    setEnglishRule(_englishRule);
  };

  const addressCascaderOptions = useMemo(() => {
    if (addressWidgetGray) {
      return CascaderOption;
    }
    return CascaderOption.filter((item) => item.value !== 'GEO_DISTANCE');
  }, [addressWidgetGray]);

  const renderTableMenu = (
    optionItem,
    groupIndex,
    fieldInd,
    isSelectedTableField,
  ) => {
    const tableChildItem = Object.keys(optionItem?.items?.properties || {}).map(
      (i) => {
        const childItem = optionItem?.items?.properties[i] || {};
        return {
          key: childItem?.id,
          label: childItem?.title,
          value: childItem?.id,
          type: 'group',
          tableId: optionItem?.id,
          tableTitle: `${optionItem?.title}_${childItem?.title}`,
          disabled: isSelectedTableField.includes(childItem?.id),
          ...childItem,
        };
      },
    );
    const tableMenuData = [
      {
        key: optionItem?.id,
        label: optionItem?.title,
        type: 'group',
        children: tableChildItem || [],
      },
    ];
    return (
      <Menu
        mode="inline"
        className="r_condition-table-menu"
        items={tableMenuData}
        onClick={(menuItem) =>
          onClickMenu(menuItem, tableChildItem, groupIndex, fieldInd)
        }
      />
    );
  };

  const onClickMenu = (menuItem, tableChildItem, groupIndex, fieldInd) => {
    const optionItem =
      (tableChildItem || [])?.filter((i) => i?.key === menuItem?.key)[0] || {};
    onHandleChange(groupIndex, fieldInd, optionItem);
  };
  return (
    <div className="crm-duplicate-fields-group">
      <div className="crm-duplicate-fields-group__tips">
        支持添加多个查重条件组，满足任意一组条件即算重复
      </div>
      <div className="crm-duplicate-fields-group__condition">
        {conditionList.map((condition, groupIndex: number) => {
          const isSelectedFieldList = condition.map((item) => item.fieldId);
          const isSelectedTableField = condition
            .filter((item) => !isEmpty(item?.tableFieldId))
            .map((i) => i.fieldId);
          return (
            <React.Fragment key={groupIndex}>
              <div className="crm-duplicate-fields-group__condition-item">
                <div className="l_title">且（满足所有项）</div>
                <div className="r_condition_wrap">
                  {condition.map((field, fieldInd: number) => (
                    <div className="r_condition-item" key={field.fieldId}>
                      <span className="r_condition-item-line"></span>
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder="请选择"
                        value={
                          field?.tableFieldId
                            ? `${field?.tableFieldTitle}_${field?.title}`
                            : field.fieldId
                        }
                        virtual={false}
                        placement="bottomLeft"
                        filterOption={(input, option) =>
                          (option?.children ?? '').includes(input)
                        }
                        onChange={(val, option) => {
                          onHandleChange(groupIndex, fieldInd, option);
                        }}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentElement
                        }
                        style={{ width: '214px', marginRight: '10px' }}
                      >
                        {duplicateData.selectFields
                          .filter(
                            (item) =>
                              !NOT_SUPPORT_WIDGETS.includes(item.widget),
                          )
                          .map((item) => {
                            let renderOption = (
                              <Option
                                disabled={isSelectedFieldList?.includes(
                                  item.id,
                                )}
                                value={item.id}
                                key={item.id}
                              >
                                {item.title}
                              </Option>
                            );
                            if (item.widget === 'table') {
                              const tableChildItem = Object.keys(
                                item?.items?.properties || {},
                              );
                              renderOption = (
                                <>
                                  <Option
                                    disabled={true}
                                    value={item.id}
                                    key={item.id}
                                  >
                                    {item.title}
                                  </Option>
                                  {tableChildItem.map((i) => {
                                    const childItem =
                                      item?.items?.properties[i] || {};
                                    return (
                                      <Option
                                        disabled={isSelectedTableField?.includes(
                                          childItem.id,
                                        )}
                                        value={childItem.id}
                                        key={childItem.id}
                                        className="r_condition_select_option_table_item"
                                        tableId={childItem?.tableId}
                                        tableTitle={childItem?.tableTitle}
                                      >
                                        {childItem.title}
                                      </Option>
                                    );
                                  })}
                                </>
                              );
                            }
                            return renderOption;
                          })}
                      </Select>
                      {field.fieldId?.includes('address') ? (
                        <Cascader
                          placeholder="请选择"
                          style={{ width: '100px', marginRight: '16px' }}
                          options={addressCascaderOptions}
                          value={[field.filterType, ...(field.values || [])]}
                          onChange={(value) => {
                            let val;
                            if (value?.length === 1) {
                              val = value[0];
                            } else {
                              val = value;
                            }
                            onChangeSearchType(groupIndex, fieldInd, val);
                          }}
                        />
                      ) : (
                        <Select
                          style={{ width: '100px', marginRight: '16px' }}
                          options={
                            field?.tableFieldId
                              ? (field?.fieldId || '').includes('phone_')
                                ? TableSearchTypeIN
                                : TableSearchTypeOption
                              : DOUBLE_SEARCHTYPE_WIDGETS.some((x) =>
                                  field.fieldId?.includes(x),
                                )
                              ? SearchTypeOption
                              : SearchOption
                          }
                          placeholder="请选择"
                          value={field.filterType}
                          onChange={(val) => {
                            onChangeSearchType(groupIndex, fieldInd, val);
                          }}
                          onSelect={handleSelected}
                        />
                      )}
                      <IconFont
                        type="icon-delete-line"
                        onClick={() =>
                          deleteConditionField(groupIndex, fieldInd)
                        }
                        className="r_condition-item-icon-delete"
                      />
                    </div>
                  ))}
                  {condition.length < 5 && (
                    <div className={cx('r_condition-add', 'r_condition-item')}>
                      <span className="r_condition-item-line"></span>
                      <IconFont
                        type="icon-add-line"
                        onClick={() => addConditionField(groupIndex)}
                        className="r_condition-item-icon-add"
                      />
                    </div>
                  )}
                </div>
              </div>
              {groupIndex !== conditionList.length - 1 && (
                <Divider
                  dashed={true}
                  style={{ color: 'rgba(126, 134, 142, 0.24)' }}
                >
                  <div className="crm-duplicate-fields-group__condition-divider">
                    或（满足任意项）
                  </div>
                </Divider>
              )}
            </React.Fragment>
          );
        })}
      </div>
      {conditionList.length < 5 && (
        <div>
          <Button onClick={addConditionGroup}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconFont type="icon-add1-line" />
              <span>新增一组查重条件</span>
            </div>
          </Button>
        </div>
      )}
      {showEnglishRule && (
        <div className="crm-duplicate-fields-group-rule">
          <h4>客户名称英文查重规则</h4>
          <div className="crm-duplicate-fields-group-rule-checkbox">
            {SELECT_ENGLISH_RULE.map(({ value, label, example, key }) => (
              <Checkbox
                key={value}
                checked={englishRule[key]}
                onChange={(e) => handleEnglishRuleChange(key, e.target.checked)}
              >
                {label}
                <span className="crm-duplicate-fields-group-rule-span">
                  例：{example}
                </span>
              </Checkbox>
            ))}
          </div>
        </div>
      )}
      <RenderDupText formName={formName} conditionList={conditionList} />
      {/* 模糊匹配设置弹窗 */}
      <FuzzyMatchingSettingModal
        visible={fuzzyMathingVisible}
        onClose={() => setFuzzyMathingVisible(false)}
      />
    </div>
  );
};

export default GroupCondition;
