/*
 * :file description:
 * :name: /low-code-platform/src/pages/generator/relation/duplicated/components/FieldSettingModal/index.tsx
 * :author: KyleLiang
 * :copyright: (c) 2024, Tungee
 * :date created: 2024-05-06 13:50:00
 * :last editor: KyleLiang
 * :date last edited: 2024-08-03 10:20:13
 */
import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { Modal, Tabs, Button, message } from 'antd';
import GroupCondition from './GroupCondition';
import DupResult from './DupResult';
import './index.less';
import { saveUkSettings } from '@/services/duplicate';
import { IDuplicateFieldItem } from '../../index';

const { TabPane } = Tabs;

const SETTING_TYPES = [
  {
    tab: '查重字段',
    key: 'fields',
    comp: GroupCondition,
  },
  {
    tab: '查重结果',
    key: 'result',
    comp: DupResult,
  },
];

export interface IComProps {
  formName: string;
  formCode: string;
  fieldsList: Array<IDuplicateFieldItem>;
}

const NewFieldSettingModal = (props, ref) => {
  const {
    duplicateData,
    visible,
    onCancel,
    formCode,
    getTableData,
    isChecked,
    formName,
    addressWidgetGray,
    bizType,
    showEnglishRule,
  } = props;
  const [activeTabKey, setActiveTabKey] = useState<string>('fields');
  const [updateDuplicateData, setUpdateDuplicateData] = useState({
    ...duplicateData,
  });

  useEffect(() => {
    setUpdateDuplicateData(duplicateData);
  }, [duplicateData]);
  useImperativeHandle(ref, () => {
    return {
      onConfirm,
    };
  });

  const handleChangeFields = (data) => {
    setUpdateDuplicateData({
      ...updateDuplicateData,
      ...data,
    });
  };

  const onHandleCancel = () => {
    setUpdateDuplicateData(duplicateData);
    if (!duplicateData?.manyQueryConditionList?.length) {
      onCancel(true);
    } else {
      onCancel();
    }
  };

  const onConfirm = (status?: boolean) => {
    const _manyQueryConditionList = updateDuplicateData.manyQueryConditionList
      .flat()
      .filter((x) => x.fieldId);
    if (status === void 0 && _manyQueryConditionList.length === 0) {
      message.warn('至少设置一组查重条件！');
      return;
    }
    const _list = updateDuplicateData.manyQueryConditionList.map((item) =>
      item?.filter((x) => x?.fieldId),
    );
    let dupStatus;
    if (status === void 0) {
      dupStatus = isChecked;
    } else {
      dupStatus = status;
    }
    return saveUkSettings({
      formCode: formCode,
      duplicationRule: {
        manyQueryConditionList: _list,
        enableIgnoreCaseSensitive:
          updateDuplicateData?.enableIgnoreCaseSensitive || undefined,
        enableExcludeSpaces:
          updateDuplicateData?.enableExcludeSpaces || undefined,
      },
      formDuplicationResultVisibleField: {
        visibleFieldList: updateDuplicateData.visibleFieldList,
      },
      status: dupStatus ? 1 : 0,
    }).then(async () => {
      message.success('保存成功！');
      await getTableData();
      onCancel();
    });
  };

  const renderContent = () => {
    return (
      <div className="crm-duplicate-modal-wrap">
        <Tabs
          activeKey={activeTabKey}
          onTabClick={(e) => {
            setActiveTabKey(e);
          }}
        >
          {SETTING_TYPES.map((t) => {
            return (
              <TabPane tab={t.tab} key={t.key}>
                {activeTabKey === t.key && (
                  <t.comp
                    tabkey={t.key}
                    formName={formName}
                    bizType={bizType}
                    duplicateData={duplicateData}
                    handleChangeFields={handleChangeFields}
                    updateDuplicateData={updateDuplicateData}
                    addressWidgetGray={addressWidgetGray}
                    showEnglishRule={showEnglishRule}
                  />
                )}
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    );
  };

  return (
    <Modal
      visible={visible}
      onCancel={onHandleCancel}
      title="查重规则设置"
      width={600}
      wrapClassName="crm-duplicate-modal"
      bodyStyle={{ padding: '0 24px' }}
      destroyOnClose
      maskClosable={false}
      footer={
        <div>
          <Button onClick={onHandleCancel}>取消</Button>
          <Button type="primary" onClick={() => onConfirm()}>
            确定
          </Button>
        </div>
      }
    >
      {renderContent()}
    </Modal>
  );
};

export default forwardRef(NewFieldSettingModal);
