/*
 * :file description:
 * :name: /low-code-platform/src/pages/generator/relation/duplicated/components/DplTable/index.tsx
 * :author: KyleLiang
 * :copyright: (c) 2024, Tungee
 * :date created: 2024-05-06 13:50:00
 * :last editor: KyleLiang
 * :date last edited: 2024-08-06 09:09:46
 */
/*
 * :file description:
 * :name: /low-code-platform/src/pages/generator/relation/duplicated/components/DplTable/index.tsx
 * :author: xiaoguotong
 * :copyright: (c) 2024, Tungee
 * :date created: 2024-01-03 17:39:21
 * :last editor: KyleLiang
 * :date last edited: 2024-08-03 15:00:09
 */

import React, {
  useState,
  useEffect,
  FC,
  useMemo,
  useRef,
  useContext,
} from 'react';
import { Table, Tooltip, Switch, Button, Radio } from 'antd';
import {
  getDuplicateFieldList,
  IVisibleField,
  IConditionField,
} from '@/services/duplicate';
import FieldSettingModal from '../FieldSettingModal';
import c from './style.less';
import RenderDupText from '../FieldSettingModal/RenderDupText';
import CombinedRule from '../CombinedRule';
import { useLocation, useModel } from 'umi';
import duplicatedContext from '../../../context';

enum SettingType {
  DuplicateCheck = 'DuplicateCheck', //查重设置
  MergeRule = 'MergeRule', //合并规则
  CombinedRule = 'CombinedRule', //联合查重
}

interface IProps {
  fieldsList: any;
  formCode: string;
  formName: string;
  addressWidgetGray: boolean;
  isPersonalCustomerCombined: boolean;
  isShowEnglishRule: boolean;
}

interface WhitelistResponse {
  result: boolean;
}

const DuplicateDetectionTable: FC<IProps> = (props) => {
  const [settingType, setSettingType] = useState(SettingType.DuplicateCheck); //当前展示设置类型

  const DuplicatedContext = useContext(duplicatedContext);

  const location = useLocation();
  const { versionValue, bizType: _bizType } = location?.query || {};

  const model = useModel('useGenerator', (model) => model);
  const { profile } = model;
  const bizType = _bizType || profile?.bizType;

  const {
    formCode,
    fieldsList,
    formName,
    addressWidgetGray,
    isPersonalCustomerCombined,
    isShowEnglishRule,
  } = props;
  const [tableData, setTableData] = useState<{
    manyQueryConditionList: Array<IConditionField>;
    visibleFieldList: Array<IVisibleField>;
    selectFields: Array<any>;
    enableIgnoreCaseSensitive: boolean;
    enableExcludeSpaces: boolean;
  }>({
    manyQueryConditionList: [[{}]],
    visibleFieldList: [],
    selectFields: fieldsList,
    enableIgnoreCaseSensitive: false,
    enableExcludeSpaces: false,
  });
  const [settingVisible, setSettingVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    if (!formCode || fieldsList.length === 0) return;
    getNewTableData();
  }, [fieldsList]);

  const handleSettingClick = () => {
    setSettingVisible(true);
  };

  const getNewTableData = () => {
    getDuplicateFieldList({
      formCode,
    }).then((res) => {
      const data = res.data ?? {};
      const {
        duplicationRule,
        formDuplicationResultVisibleField,
        status,
      } = data;
      setIsChecked(!!status);
      setTableData({
        selectFields: fieldsList,
        manyQueryConditionList: duplicationRule?.manyQueryConditionList || [],
        visibleFieldList:
          formDuplicationResultVisibleField?.visibleFieldList || [],
        // 是否区分大小写
        enableIgnoreCaseSensitive:
          duplicationRule?.enableIgnoreCaseSensitive || false,
        // 是否区分特殊字符
        enableExcludeSpaces: duplicationRule?.enableExcludeSpaces || false,
      });
    });
  };

  const handleChange = async (checked: boolean) => {
    await modalRef.current?.onConfirm(checked);
    if (!tableData?.manyQueryConditionList?.length && checked) {
      handleSettingClick();
    }
  };

  const handleClose = (isSetRule?: boolean) => {
    if (isSetRule && isChecked) {
      handleChange(false);
    }
    setSettingVisible(false);
  };

  const handleTypeChange = (v: SettingType) => {
    setSettingType(v);
  };

  return (
    <div className={c['crm-duplicate-detection-table']}>
      <h4 className={c.title}>查重启用状态</h4>
      <div className={c.flex}>
        <Switch
          checked={isChecked}
          onChange={(e: any) => {
            setIsChecked(e);
            handleChange(e);
          }}
        />
        <div>{`已${
          isChecked ? '开启' : '关闭'
        }查重规则，新增/编辑表单时，重复数据${
          isChecked ? '不' : ''
        }可保存`}</div>
      </div>

      <div className={c['type-select']} style={{ display: 'none' }}>
        <Radio.Group
          onChange={(e) => {
            handleTypeChange(e?.target?.value);
          }}
          value={settingType}
        >
          <Radio.Button value={SettingType.DuplicateCheck}>
            查重设置
          </Radio.Button>
          {/*开启灰度并且自建客户表才有联合查重功能 */}
          {['crm_customer_personal', 'crm_customer', 'crm_contact'].includes(
            bizType,
          ) &&
            DuplicatedContext?.unionRepeated &&
            DuplicatedContext?.byoCustomer && (
              <Radio.Button value={SettingType.CombinedRule}>
                联合查重
              </Radio.Button>
            )}
        </Radio.Group>
      </div>

      {/* 个人客户表联合查重功能 */}
      <div
        className={c['type-select']}
        style={isPersonalCustomerCombined ? {} : { display: 'none' }}
      >
        <Radio.Group
          onChange={(e) => {
            handleTypeChange(e?.target?.value);
          }}
          value={settingType}
        >
          <Radio.Button value={SettingType.DuplicateCheck}>
            查重设置
          </Radio.Button>
          {/*开启灰度并且自建客户表才有联合查重功能 */}
          {['crm_customer_personal'].includes(bizType) && (
            <Radio.Button value={SettingType.CombinedRule}>
              联合查重
            </Radio.Button>
          )}
        </Radio.Group>
      </div>

      {/* 查重规则 */}
      {settingType === SettingType.DuplicateCheck && (
        <>
          <div className={c.title}>
            <Button type="primary" onClick={handleSettingClick}>
              设置查重规则
            </Button>
          </div>
          <div className={c.list}>
            <div>查重字段</div>
            {tableData.manyQueryConditionList.map((condition) => {
              return (
                <div>
                  {condition
                    ?.map((item) =>
                      item?.tableFieldId
                        ? `${item?.tableFieldTitle}_${item?.title}`
                        : item.title,
                    )
                    .join('、')}
                </div>
              );
            })}
          </div>
          <RenderDupText
            formName={formName}
            conditionList={tableData.manyQueryConditionList}
          />
        </>
      )}
      {/* 联合查重 */}
      {settingType === SettingType.CombinedRule && (
        <>
          <CombinedRule
            fieldsList={fieldsList}
            formName={formName}
            isPersonalCustomerCombined={isPersonalCustomerCombined}
          />
        </>
      )}

      <FieldSettingModal
        visible={settingVisible}
        formCode={formCode}
        bizType={bizType}
        onCancel={handleClose}
        ref={modalRef}
        isChecked={isChecked}
        formName={formName}
        addressWidgetGray={addressWidgetGray}
        getTableData={getNewTableData}
        duplicateData={tableData}
        showEnglishRule={isShowEnglishRule}
      />
    </div>
  );
};

export default DuplicateDetectionTable;
