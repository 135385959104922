/*
 * :file description:
 * :name: /low-code-platform/src/pages/generator/relation/duplicated/constants.ts
 * :author: KyleLiang
 * :copyright: (c) 2024, Tungee
 * :date created: 2024-05-06 13:50:00
 * :last editor: KyleLiang
 * :date last edited: 2024-08-05 18:33:55
 */
/*
 * @Description:
 * @FilePath: /low-code-platform/src/pages/generator/relation/duplicated/constants.ts
 * :author: wu.shangru
 * :copyright: (c) 2023, Tungee
 * @Date: 2023-07-30 18:37:15
 * @LastEditors: wu.shangru
 * @LastEditTime: 2023-12-02 09:59:44
 */
export const SearchTypeOption = [
  {
    label: '精确查询',
    value: 'EQ',
  },
  {
    label: '模糊查询',
    value: 'SEARCH',
  },
];

export const TableSearchTypeOption = [
  {
    label: '精确查询',
    value: 'IN',
  },
  {
    label: '模糊查询',
    value: 'SEARCH',
  },
];

export const TableSearchTypeIN = [
  {
    label: '精确查询',
    value: 'IN',
  },
];

export const SearchOption = [
  {
    label: '精确查询',
    value: 'EQ',
  },
];

export const CascaderOption = [
  {
    label: '精确查询',
    value: 'EQ',
  },
  {
    label: '范围',
    value: 'GEO_DISTANCE',
    children: [
      {
        label: '500m内',
        value: '0.5',
      },
      {
        label: '1km内',
        value: '1',
      },
      {
        label: '2km内',
        value: '2',
      },
      {
        label: '3km内',
        value: '3',
      },
    ],
  },
];

// 表格内支持查重的字段
export const TABLE_SUPPORT_DUPLICAT_WIDGETS = ['input', 'phone'];

export const EXTRA_SUPPORT_DUPLICAT_WIDGETS = ['table'];

export const SUPPORT_DUPLICAT_WIDGETS = [
  'input',
  'textarea',
  'phone',
  'number',
  'select',
  'seqNumber',
  'multiSelect',
  'department',
  'date',
  'dateRange',
  'money',
  'address',
  'innerContact',
  'relation',
  'principal',
  'cooperator',
];

export const PERSONAL_CUSTOMER_SUPPORT_DUPLICAT_WIDGETS = ['input', 'phone'];

export const DOUBLE_SEARCHTYPE_WIDGETS = ['input', 'textarea'];

export const NOT_SUPPORT_WIDGETS = [
  'principal',
  'cooperator',
  'seqNumber',
  'multiSelect',
  'dateRange',
];

export const GMT_CREATE = {
  fieldId: 'gmtCreate',
  id: 'gmtCreate',
  title: '创建时间',
  hidden: false,
  editable: true,
};

export const BIZ_CUSTOMER_PRE_PRINCIPALS = {
  fieldId: 'biz_customer_pre_principals',
  id: 'biz_customer_pre_principals',
  title: '最后负责人',
  hidden: false,
  editable: true,
};

export const HIDDEN_IN_DUPLICATED_WIDGETID = [
  'input_lastSmartVoiceDuration',
  'input_smartVoiceTotalDuration',
  'phone_lastSmartVoicePhone',
  'date_lastSmartVoiceDate',
  'date_lastSmartVoiceTaskDate',
  'number_smartVoiceTimes',
  'input_lastSmartVoiceIntentionRank',
  'number_smartVoiceTaskTimes',
  'input_smartVoiceIntentionRanks',
];

export const PROCESS_FORM_FIELDS = [
  {
    enum: ['1', '2', '3'],
    enumNames: ['已同意', '已撤回', '已拒绝'],
    items: {
      type: 'string',
    },
    placeholder: '请选择',
    title: '审批结果',
    type: 'array',
    widget: 'select',
    id: 'lastProcessResult',
  },
  {
    enum: ['1', '2', '3'],
    enumNames: ['审批中', '审批完成', '审批终止'],
    items: {
      type: 'string',
    },
    placeholder: '请选择',
    title: '审批状态',
    type: 'array',
    widget: 'select',
    id: 'lastProcessStatus',
  },
];

//区分企业的英文查重规则
export const SELECT_ENGLISH_RULE = [
  {
    value: '0',
    label: '不区分大小写',
    example: 'NVIDIA Co. Ltd 与 nvidia co.ltd 视为同一家企业',
    key: 0,
  },
  {
    value: '1',
    label: '排除空格、特殊字符、ltd、lnc',
    example: 'NVIDIA Co. Ltd 与 nvidia.co 视为同一家企业',
    key: 1,
  },
];
